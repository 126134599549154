import React from 'react';
import styled from 'styled-components';

const BookSection = styled.section`
  padding: 3rem 2rem;
  background-color: #1a1a1a;
  color: #e0e0e0;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #00bcd4;
  margin-bottom: 1rem;
`;

const Description = styled.div`
  max-width: 800px;
  margin: 0 auto 2rem auto;
  line-height: 1.8;
  
  p {
    margin-bottom: 1rem;
    font-weight: 300;
    font-size: 1.1rem;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  background-color: #1f1f1f;
  padding: 1.5rem;
  border-radius: 8px;
  min-width: 180px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  h3 {
    color: #00bcd4;
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  p {
    color: #e0e0e0;
    font-size: 0.9rem;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background: #00bcd4;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s;
  margin-top: 2rem;

  &:hover {
    background: #008ba3;
  }
`;

const BookHighlight = () => (
  <BookSection>
    <Title>Do Backend à Liberdade Financeira com IA</Title>
    <Description>
      <p>
        Depois de anos desenvolvendo sistemas escaláveis e liderando equipes de tecnologia, 
        descobri um potencial inexplorado na intersecção entre IA e empreendedorismo digital. 
        Transformei esse conhecimento em 25 modelos de negócio práticos e validados.
      </p>
      <p>
        Em "25 Negócios Digitais com IA", compartilho o passo a passo completo de como 
        qualquer pessoa pode construir uma fonte de renda consistente aproveitando o poder da 
        Inteligência Artificial - mesmo sem conhecimento técnico. São oportunidades reais 
        que estão mudando a vida de centenas de empreendedores.
      </p>
    </Description>

    <StatsContainer>
      <StatItem>
        <h3>25</h3>
        <p>Modelos Prontos para Implementar</p>
      </StatItem>
      <StatItem>
        <h3>48h</h3>
        <p>Do Zero ao Primeiro Faturamento</p>
      </StatItem>
      <StatItem>
        <h3>R$3-5k</h3>
        <p>Faturamento Médio Inicial</p>
      </StatItem>
    </StatsContainer>

    <CTAButton 
      href="https://negocios-digitais-com-ia.balsalobre.dev"
      target="_blank"
      rel="noopener noreferrer"
    >
      Transforme sua Vida com IA →
    </CTAButton>
  </BookSection>
);

export default BookHighlight;