import React from 'react';
import GlobalStyle from './components/GlobalStyle';
import Header from './components/Header';
import BookHighlight from './components/BookHighlight';
import About from './components/About';
import Experience from './components/Experience';
import Skills from './components/Skills';
import FinalCTA from './components/FinalCTA';
import Contact from './components/Contact';

function App() {
  return (
    <>
      <GlobalStyle />
      <Header />
      <BookHighlight />
      <About />
      <Experience />
      <Skills />
      <FinalCTA />
      <Contact />
    </>
  );
}

export default App;