import React from 'react';
import styled from 'styled-components';

const CTASection = styled.section`
  padding: 3rem 2rem;
  background-color: #1a1a1a;
  color: #e0e0e0;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #00bcd4;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const CTAButton = styled.a`
  display: inline-block;
  background: #00bcd4;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s;

  &:hover {
    background: #008ba3;
  }
`;

const FinalCTA = () => (
  <CTASection>
    <Container>
      <Title>Pronto para Começar sua Jornada com IA?</Title>
      <Description>
        Descubra como implementar seu próprio negócio digital utilizando Inteligência Artificial e 
        comece a gerar resultados em 48 horas.
      </Description>
      <CTAButton 
        href="https://negocios-digitais-com-ia.balsalobre.dev"
        target="_blank"
        rel="noopener noreferrer"
      >
        Começar Agora →
      </CTAButton>
    </Container>
  </CTASection>
);

export default FinalCTA;